
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import PermissionsTable from '@/components/roles/PermissionsTable.vue';
  import * as Yup from 'yup';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'roles-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      PermissionsTable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const selectedPermissions = ref([]);
      const selectedGroup = ref(route.params.id);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const roleSchema = Yup.object().shape({
        title: Yup.string()
          .required(() => translate('ROLE_NAME_IS_REQUIRED_FIELD'))
          .label('Name'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.permissions = selectedPermissions.value;

        values.groupId = route.params.id;

        await store.dispatch(Actions.CREATE_ROLE, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ROLE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'groups-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('rolesCreating'), [
          translate('members'),
        ]);
      });

      await store.dispatch(Actions.GET_ROLES_GROUPS);

      const updatePermissionsList = (list) => {
        selectedPermissions.value = list;
      };

      return {
        groups: computed(() => store.getters.rolesGroupsList),
        updatePermissionsList,
        onSubmitCreate,
        submitButton,
        translate,
        roleSchema,
        goBack,
        selectedGroup,
        can,
      };
    },
  });
